<template>
  <div class="new-order__main">
    <div class="container">
      <div class="new-order__part">
        <div class="new-order__part-icon">
          <div class="icon-button icon-button--round icon-button--blue">
            <i class="ri-arrow-up-circle-line"></i>
          </div>
        </div>
        <Multiselect
          :value="pickup"
          :options="pickups"
          :placeholder="pickup ? pickup.businessName : 'Pick Up'"
          class="new-order-search"
          track-by="id"
          label="businessName"
          :show-labels="false"
          :allow-empty="false"
          :loading="isLoadingPickup"
          @select="changePickup"
          @search-change="searchPickup"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="label__item">
              <span class="label__title">{{ props.option.businessName }}</span>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__item">
              <div class="option__item-icon">
                <i class="ri-checkbox-circle-fill"></i>
              </div>
              <div class="option__item-customer">
                <div class="option__item-name">
                  <span v-if="props.option.fathersName">{{ props.option.fathersName }}’ </span>
                  <span>{{ props.option.firstName }} </span>
                  <span>{{ props.option.middleInitial }} </span>
                  <span>{{ props.option.lastName }} </span>
                  <span v-if="props.option.nickName">({{ props.option.nickName }})</span>
                </div>
                <div class="option__item-name">
                  <span>{{ props.option.businessName }}</span>
                </div>
                <div class="option__item-address">
                  <span>Address: </span>
                  <span>{{ props.option.address }} {{ props.option.city }}</span>
                </div>
              </div>
              <div v-if="props.option.isEnterprise" class="option__item-enterprise">Enterprise</div>
            </div>
          </template>
          <template slot="noOptions">
            <div class="label__item--options">
              <span class="label__title">Please type business name</span>
            </div>
          </template>
          <template slot="noResult">
            <div class="label__item--options">
              <span class="label__title">No business name like that, please try again</span>
            </div>
          </template>
        </Multiselect>
        <div class="new-order__part-button">
          <button
            class="button button--fill button--fill--blue"
            :class="{'button--fill--grey': pickupBtn}"
            @click="chooseMe('pickup')"
          >
            Choose me
          </button>
        </div>
      </div>
      <div class="new-order__part">
        <div class="new-order__part-icon">
          <div class="icon-button icon-button--round icon-button--orange">
            <i class="ri-arrow-down-circle-line"></i>
          </div>
        </div>
        <Multiselect
          :value="dropOff"
          :options="dropOffs"
          :placeholder="dropOff ? dropOff.businessName : 'Drop-off'"
          class="new-order-search"
          track-by="id"
          label="businessName"
          :show-labels="false"
          :allow-empty="false"
          :loading="isLoadingDropOff"
          @select="changeDropOff"
          @search-change="searchDropOff"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="label__item">
              <span class="label__title">{{ props.option.businessName }}</span>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__item">
              <div class="option__item-icon">
                <i class="ri-checkbox-circle-fill"></i>
              </div>
              <div class="option__item-customer">
                <div class="option__item-name">
                  <span v-if="props.option.fathersName">{{ props.option.fathersName }}’ </span>
                  <span>{{ props.option.firstName }} </span>
                  <span>{{ props.option.middleInitial }} </span>
                  <span>{{ props.option.lastName }} </span>
                  <span v-if="props.option.nickName">({{ props.option.nickName }})</span>
                </div>
                <div class="option__item-name">
                  <span>{{ props.option.businessName }}</span>
                </div>
                <div class="option__item-address">
                  <span>Address: </span>
                  <span>{{ props.option.address }} {{ props.option.city }}</span>
                </div>
              </div>
            </div>
          </template>
          <template slot="noOptions">
            <div class="label__item--options">
              <span class="label__title">Please type business name</span>
            </div>
          </template>
          <template slot="noResult">
            <div class="label__item--options">
              <span class="label__title">No business name like that, please try again</span>
            </div>
          </template>
        </Multiselect>
        <div class="new-order__part-button">
          <button
            class="button button--fill button--fill--blue"
            :class="{'button--fill--grey': dropOffBtn}"
            @click="chooseMe('dropOff')"
          >
            Choose me
          </button>
        </div>
      </div>
      <div class="new-order__part">
        <div class="new-order__part-icon">
          <div class="icon-button icon-button--round icon-button--blue">
            <i class="ri-building-2-fill"></i>
          </div>
        </div>
        <Multiselect
          :value="employee"
          :options="userInfo.employeeNames || []"
          :placeholder="employee ? employee : 'Employee'"
          class="new-order-search"
          :show-labels="false"
          :allow-empty="false"
          open-direction="bottom"
          @select="changeEmployee"
        >
          <template slot="singleLabel" slot-scope="{option}">
            <div class="label__item">
              <span class="label__title">{{ option }}</span>
            </div>
          </template>
          <template slot="option" slot-scope="{option}">
            <div class="option__item">
              <div class="option__item-icon">
                <i class="ri-checkbox-circle-fill"></i>
              </div>
              <div class="option__item-customer">
                <div class="option__item-name">
                  <span>{{ option }} </span>
                </div>
              </div>
            </div>
          </template>
        </Multiselect>
      </div>
      <hr />
      <div class="new-order__part">
        <date-picker
          :value="date"
          format="YYYY-MM-DD"
          placeholder="Select date"
          type="date"
          :lang="lang"
          :disabled-date="disableWeekends"
          popup-class="new-order-date-popup"
          class="new-order-date"
          @input="changeDate"
        >
          <template slot="icon-calendar">
            <i class="ri-calendar-fill"></i>
          </template>
        </date-picker>
      </div>
      <!-- <div class="new-order__part">
        <div class="new-order__part-icon">
          <div class="icon-button icon-button--round icon-button--blue">
            <i class="ri-account-pin-circle-fill"></i>
          </div>
        </div>
        <Multiselect
          :value="billing"
          :options="billings"
          :placeholder="billing ? billing.businessName : 'Billing'"
          class="new-order-search"
          track-by="id"
          label="businessName"
          :show-labels="false"
          :allow-empty="false"
          :loading="isLoadingBilling"
          @select="changeBilling"
          @search-change="searchBilling"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="label__item">
              <span class="label__title">{{ props.option.businessName }}</span>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__item">
              <div class="option__item-icon">
                <i class="ri-checkbox-circle-fill"></i>
              </div>
              <div class="option__item-customer">
                <div class="option__item-name">{{ $dots(props.option.businessName, 40) }}</div>
                <div class="option__item-fname">
                  <span>(FatherName)</span>
                  <span>{{ $dots(props.option.fathersName, 18) }}</span>
                </div>
                <div class="option__item-address">
                  <span>Address: </span>
                  <span>{{ $dots(props.option.address, 40) }}</span>
                </div>
              </div>
              <div class="option__item-nick">{{ props.option.nickName }}</div>
            </div>
          </template>
        </Multiselect>
        <div class="new-order__part-button">
          <button class="button button--fill button--fill--blue" @click="chooseMe('billing')">
            Choose me
          </button>
        </div>
      </div> -->
      <div class="new-order__part">
        <label class="new-order__label">
          <textarea
            :value="note"
            class="new-order__textarea"
            placeholder="Add a note here if required..."
            @input="changeNote"
          ></textarea>
        </label>
      </div>
      <FilesUpload />
    </div>
  </div>
</template>

<script>
import FilesUpload from './FilesUpload'
import {mapGetters, mapState, mapMutations} from 'vuex'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'PublicNewOrderCustomer',
  components: {Multiselect, FilesUpload, DatePicker},
  data() {
    return {
      localDate: new Date(),
      dropOffBtn: false,
      pickupBtn: false,
      isLoadingPickup: false,
      isLoadingDropOff: false,
      isLoadingBilling: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  computed: {
    ...mapState({
      customer: s => s.payment.customers.customer,
      pickup: s => s.newOrder.pickup,
      pickups: s => s.newOrder.searchPickup.customers,
      dropOff: s => s.newOrder.dropOff,
      dropOffs: s => s.newOrder.searchDropOff.customers,
      billing: s => s.newOrder.billing,
      billings: s => s.newOrder.searchBilling.customers,
      note: s => s.newOrder.note,
      date: s => s.newOrder.date,
      userInfo: s => s.client.info,
      employee: s => s.newOrder.employee
    }),
    ...mapGetters({
      customerID: 'payment/customers/idCustomer'
    })
  },
  watch: {
    pickup(cus) {
      this.customerID !== cus.id ? (this.dropOffBtn = false) : null
    },
    dropOff(cus) {
      this.customerID !== cus.id ? (this.pickupBtn = false) : null
    }
  },
  mounted() {
    this.$store.commit('newOrder/setBilling', this.customer)
    this.$store.commit('newOrder/setDate', this.localDate)
  },
  methods: {
    ...mapMutations({
      setPickup: 'newOrder/setPickup',
      setDropOff: 'newOrder/setDropOff',
      setBilling: 'newOrder/setBilling',
      setEmployee: 'newOrder/setEmployee',
      setNote: 'newOrder/setNote',
      setDate: 'newOrder/setDate'
    }),
    changePickup(cus) {
      this.setPickup(cus)
    },
    changeDropOff(cus) {
      this.setDropOff(cus)
    },
    changeBilling(cus) {
      this.setBilling(cus)
    },
    changeEmployee(cus) {
      this.setEmployee(cus)
    },
    changeNote(e) {
      this.setNote(e.target.value)
    },
    searchPickup: _.debounce(async function(e) {
      if (!e) return
      this.isLoadingPickup = true
      this.$store.commit('newOrder/searchPickup/reset')
      await this.$store.dispatch('newOrder/searchPickup/fetch', {
        'filters[generalSearch]': e,
        'filters[publicOrCompanyID]': process.env.VUE_APP_COMPANY_ID
      })
      this.isLoadingPickup = false
    }, 500),
    searchDropOff: _.debounce(async function(e) {
      if (!e) return
      this.isLoadingDropOff = true
      this.$store.commit('newOrder/searchDropOff/reset')
      await this.$store.dispatch('newOrder/searchDropOff/fetch', {
        'filters[generalSearch]': e,
        'filters[publicOrCompanyID]': process.env.VUE_APP_COMPANY_ID
      })
      this.isLoadingDropOff = false
    }, 500),
    searchBilling: _.debounce(async function(e) {
      if (!e) return
      this.isLoadingBilling = true
      this.$store.commit('newOrder/searchBilling/reset')
      await this.$store.dispatch('newOrder/searchBilling/fetch', {
        'filters[generalSearch]': e,
        'filters[publicOrCompanyID]': process.env.VUE_APP_COMPANY_ID
      })
      this.isLoadingBilling = false
    }, 500),
    async chooseMe(type) {
      const customer = this.$store.getters['payment/customers/customer']
      if (type === 'pickup' && !this.pickupBtn) {
        this.$store.commit('newOrder/setPickup', customer)
        this.dropOffBtn = true
        this.pickupBtn = false
      } else if (type === 'dropOff' && !this.dropOffBtn) {
        this.$store.commit('newOrder/setDropOff', customer)
        this.pickupBtn = true
        this.dropOffBtn = false
      } else if (type === 'billing') {
        this.$store.commit('newOrder/setBilling', customer)
      }
    },
    changeDate(date) {
      this.setDate(date)
    },
    disableWeekends(date) {
      const day = new Date(date).getDay()
      return day === 0 || day === 6 || new Date().getTime() - 3600 * 1000 * 24 > date
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
