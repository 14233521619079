var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mobile
    ? _c("div", { staticClass: "page-wrapper" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-header-inner" }, [
              _c("div", { staticClass: "new-order__nav" }, [
                _c("div", { staticClass: "new-order__nav-title" }, [
                  _vm._v("Book New Order with STS")
                ]),
                _c(
                  "button",
                  {
                    staticClass: "icon-button icon-button--round",
                    on: { click: _vm.closePage }
                  },
                  [_c("i", { staticClass: "ri-close-circle-fill" })]
                )
              ]),
              _c("div", { staticClass: "new-order__steps" }, [
                _c(
                  "div",
                  {
                    staticClass: "new-order__step",
                    class: { "new-order__step--active": _vm.step === 1 },
                    on: {
                      click: function($event) {
                        return _vm.changeStep(1)
                      }
                    }
                  },
                  [_vm._v("1")]
                ),
                _c("div", {
                  staticClass:
                    "new-order__step-line new-order__step-line--solid"
                }),
                _c(
                  "div",
                  {
                    staticClass: "new-order__step",
                    class: { "new-order__step--active": _vm.step === 2 },
                    on: {
                      click: function($event) {
                        return _vm.changeStep(2)
                      }
                    }
                  },
                  [_vm._v("2")]
                ),
                _c("div", { staticClass: "new-order__step-line" }),
                _c(
                  "div",
                  {
                    staticClass: "new-order__step",
                    class: { "new-order__step--active": _vm.step === 3 },
                    on: {
                      click: function($event) {
                        return _vm.changeStep(3)
                      }
                    }
                  },
                  [_vm._v("3")]
                )
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "page-main" },
          [
            _vm.step === 1 ? _c("Customer") : _vm._e(),
            _vm.step === 2 ? _c("Service") : _vm._e(),
            _vm.step === 3 ? _c("Review") : _vm._e(),
            _c("div", { staticClass: "new-order__footer" }, [
              _c(
                "div",
                {
                  staticClass: "new-order__button",
                  class: {
                    "new-order__button--blue": _vm.nextBtnActive,
                    "new-order__button--disabled": !_vm.nextBtnActive,
                    "new-order__button--green": _vm.step === 2
                  },
                  on: { click: _vm.nextStep }
                },
                [
                  _c("span", { staticClass: "new-order__button-text" }, [
                    _vm._v(_vm._s(_vm.btnText))
                  ]),
                  _c("i", { staticClass: "ri-arrow-right-circle-fill" })
                ]
              )
            ])
          ],
          1
        )
      ])
    : _c("New-order-desktop")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }