var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-order__main" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "new-order__main-title" }, [
          _vm._v("Choose Pickup & Dropoff")
        ]),
        _c("div", { staticClass: "new-order__part" }, [
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _vm._m(0),
            _c("div", { staticClass: "new-order__customer-inner" }, [
              _c("div", { staticClass: "new-order__customer-name" }, [
                _c("div", { staticClass: "new-order__customer-subtitle" }, [
                  _vm._v("Pickup")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "new-order__customer-title",
                    on: {
                      click: function($event) {
                        return _vm.openSearch("pickup")
                      }
                    }
                  },
                  [
                    _vm.pickup && _vm.pickup.businessName
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$dots(_vm.pickup.businessName, 20)))
                        ])
                      : _c(
                          "span",
                          { staticClass: "new-order__customer-desc" },
                          [_vm._v("Pickup destination")]
                        )
                  ]
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "button button--fill button--fill--blue",
                  class: { "button--fill--grey": _vm.pickupBtn },
                  on: {
                    click: function($event) {
                      return _vm.chooseMe("pickup")
                    }
                  }
                },
                [_vm._v(" Choose me ")]
              )
            ])
          ]),
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _vm._m(1),
            _c("div", { staticClass: "new-order__customer-inner" }, [
              _c("div", { staticClass: "new-order__customer-name" }, [
                _c("div", { staticClass: "new-order__customer-subtitle" }, [
                  _vm._v("Dropoff")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "new-order__customer-title",
                    on: {
                      click: function($event) {
                        return _vm.openSearch("dropOff")
                      }
                    }
                  },
                  [
                    _vm.dropOff && _vm.dropOff.businessName
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$dots(_vm.dropOff.businessName, 20))
                          )
                        ])
                      : _c(
                          "span",
                          { staticClass: "new-order__customer-desc" },
                          [_vm._v("Dropoff destination")]
                        )
                  ]
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "button button--fill button--fill--blue",
                  class: { "button--fill--grey": _vm.dropOffBtn },
                  on: {
                    click: function($event) {
                      return _vm.chooseMe("dropOff")
                    }
                  }
                },
                [_vm._v(" Choose me ")]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass:
                "new-order__customer-wrapper new-order__customer-wrapper--select"
            },
            [
              _vm._m(2),
              _c("Multiselect", {
                staticClass: "new-order-search multiselect--employee",
                attrs: {
                  value: _vm.employee,
                  options: _vm.userInfo.employeeNames || [],
                  placeholder: _vm.employee ? _vm.employee : "Employee",
                  "show-labels": false,
                  "allow-empty": false
                },
                on: { select: _vm.changeEmployee },
                scopedSlots: _vm._u([
                  {
                    key: "singleLabel",
                    fn: function(ref) {
                      var option = ref.option
                      return [
                        _c("div", { staticClass: "label__item" }, [
                          _c("span", { staticClass: "label__title" }, [
                            _vm._v(_vm._s(option))
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "option",
                    fn: function(ref) {
                      var option = ref.option
                      return [
                        _c("div", { staticClass: "option__item" }, [
                          _c("div", { staticClass: "option__item-icon" }, [
                            _c("i", { staticClass: "ri-checkbox-circle-fill" })
                          ]),
                          _c("div", { staticClass: "option__item-customer" }, [
                            _c("div", { staticClass: "option__item-name" }, [
                              _c("span", [_vm._v(_vm._s(option) + " ")])
                            ])
                          ])
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "new-order__part" }, [
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _c("div", { staticClass: "new-order__customer-label" }, [
              _c("i", { staticClass: "ri-calendar-fill" }),
              _c("input", {
                staticClass: "new-order__customer-input",
                attrs: { min: _vm.localDate, type: "date" },
                domProps: { value: _vm.date },
                on: { change: _vm.changeDate }
              })
            ])
          ])
        ]),
        _c("FilesUpload")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--blue" },
      [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-order__part-icon" }, [
      _c(
        "div",
        { staticClass: "icon-button icon-button--round icon-button--blue" },
        [_c("i", { staticClass: "ri-building-2-fill" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }