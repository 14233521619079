var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-order__main" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "new-order__main-title" }, [
        _vm._v("Review & Book")
      ]),
      _c("div", { staticClass: "new-order__part" }, [
        _vm.pickup
          ? _c("div", { staticClass: "new-order__customer-wrapper" }, [
              _c("div", { staticClass: "new-order__customer-type" }, [
                _vm._v("Pickup")
              ]),
              _c("div", { staticClass: "new-order__customer" }, [
                _vm._m(0),
                _c("div", { staticClass: "new-order__customer-info" }, [
                  _c("div", { staticClass: "new-order__customer-name" }, [
                    _vm._v(
                      _vm._s(_vm.$dots(_vm.pickup.businessName, _vm.numChar))
                    )
                  ]),
                  _c("div", { staticClass: "new-order__customer-address" }, [
                    _vm._v(_vm._s(_vm.$dots(_vm.pickup.address, _vm.numChar)))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.dropOff
          ? _c("div", { staticClass: "new-order__customer-wrapper" }, [
              _c("div", { staticClass: "new-order__customer-type" }, [
                _vm._v("Dropoff")
              ]),
              _c("div", { staticClass: "new-order__customer" }, [
                _vm._m(1),
                _c("div", { staticClass: "new-order__customer-info" }, [
                  _c("div", { staticClass: "new-order__customer-name" }, [
                    _vm._v(
                      _vm._s(_vm.$dots(_vm.dropOff.businessName, _vm.numChar))
                    )
                  ]),
                  _c("div", { staticClass: "new-order__customer-address" }, [
                    _vm._v(_vm._s(_vm.$dots(_vm.dropOff.address, _vm.numChar)))
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "new-order__part" }, [
        _vm.photo
          ? _c("div", { staticClass: "new-order__photo-wrapper" }, [
              _c("div", { staticClass: "new-order__photo-title" }, [
                _vm._v("Photo")
              ]),
              _c(
                "div",
                { staticClass: "new-order__photo" },
                [
                  _c("el-image", {
                    staticClass: "new-order__photo-img",
                    attrs: {
                      src: _vm.photo,
                      "preview-src-list": [_vm.photo],
                      fit: "cover"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.note
          ? _c("div", { staticClass: "new-order__note-wrapper" }, [
              _c("div", { staticClass: "new-order__note-title" }, [
                _vm._v("Note")
              ]),
              _c("div", { staticClass: "new-order__note" }, [
                _vm._v(_vm._s(_vm.note))
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "new-order__part" }, [
        _c("div", { staticClass: "new-order__card-wrapper" }, [
          _c("div", { staticClass: "new-order__card-total" }, [
            _c("div", { staticClass: "new-order__card-title" }, [
              _vm._v("Service Name")
            ]),
            _vm.cost
              ? _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v(_vm._s(_vm.service.name))
                ])
              : _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("—")
                ])
          ]),
          _c("div", { staticClass: "new-order__card-total" }, [
            _c("div", { staticClass: "new-order__card-title" }, [
              _vm._v("Fuel Surcharge")
            ]),
            _vm.cost
              ? _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("$" + _vm._s(_vm.cost.fuelSurcharge))
                ])
              : _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("—")
                ])
          ]),
          _c("div", { staticClass: "new-order__card-total" }, [
            _c("div", { staticClass: "new-order__card-title" }, [
              _vm._v("HST")
            ]),
            _vm.cost
              ? _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("$" + _vm._s(_vm.cost.hst))
                ])
              : _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("—")
                ])
          ]),
          _c("div", { staticClass: "new-order__card-total" }, [
            _c("div", { staticClass: "new-order__card-title" }, [
              _vm._v("Total")
            ]),
            _vm.cost
              ? _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("$" + _vm._s(_vm.cost.total))
                ])
              : _c("div", { staticClass: "new-order__card-price" }, [
                  _vm._v("—")
                ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--blue" },
      [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }